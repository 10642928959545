<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <div v-if="!success">
            Вы действительно хотите отписаться от уведомлений ИС Мониторинга СЗН?
            <div class="save-buttons">
              <v-btn color="primary" @click="unsubscribe()">Продолжить</v-btn>
            </div>
          </div>
          <div v-else>
            Вы успешно отписаны от уведомлений
          </div>
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  props: {},
  components: {},
  data() {
    return {
      apiLoaded: true,
      errorText: null,
      success: false
    };
  },
  filters: {},
  methods: {
    async unsubscribe() {
      let req = await this.$postApi("/unsubscribe", {})
        if (req.ok) {
          this.success = true
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        }
      
    }
  },
  async beforeMount() {

  }
};
</script>
